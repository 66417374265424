import React, { useEffect, useState } from "react";
import Header_Layout from "../../components/layouts/Header_Layout";
import { HeadFC, graphql, useStaticQuery } from "gatsby";
import { Link, Typography } from "@mui/material";

const page_styles = {
  color: "#232129",
  paddingTop: 16,
  paddingBottom: 96,
  paddingLeft: 96,
  paddingRight: 96,
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
  fontSize: 24,
};

type PageNode = {
  id: string;
  frontmatter: PageFrontmatter;
};

type PageFrontmatter = {
  slug: string;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  author: string;
  hide?: boolean;
};

const Blogs = () => {
  const pages = useStaticQuery(graphql`
    query {
      allMdx {
        pageInfo {
          pageCount
        }
        nodes {
          frontmatter {
            created_at
            updated_at
            description
            slug
            title
            author
            hide
          }
        }
      }
    }
  `);

  const [page_data, set_page_data] = useState<PageNode[] | undefined>();

  useEffect(() => {
    set_page_data(pages.allMdx.nodes);
  }, [pages]);

  return (
    <Header_Layout>
      <main style={page_styles}>
        <Typography variant="h2">The Manual</Typography>
        <Typography variant="body1">
          Here you'll find helpful guides and relevant content about desktop
          PCs. Sign up or join our Discord to keep posted on updates here.
        </Typography>
        <br></br>
        {!!page_data?.length
          ? page_data.map(
              (p) =>
                !p.frontmatter.hide && (
                  <>
                    <Link href={`/manual/${p.frontmatter.slug}`}>
                      {p.frontmatter.title}
                    </Link>
                    <br></br>
                    <br></br>
                  </>
                )
            )
          : undefined}
      </main>
    </Header_Layout>
  );
};

export default Blogs;

export const Head: HeadFC = () => <title>The Manual - BitLot.app</title>;
